<template>
  <b-card>
    <transition
      name="fade"
      mode="out-in"
    >
      <training-courses-edit-form
        v-if="trainingCourseData"
        :training-course-data="trainingCourseData"
        :send-information-email.sync="sendInformationEmail"
        :loading="loading"
        mode="edit"
        @submit="submit"
      />
      <div
        v-else
        class="text-center"
      >
        <b-spinner style="width: 4em; height: 4em;" />
      </div>
    </transition>
  </b-card>
</template>

<script>
import {
  BCard, BSpinner,
} from 'bootstrap-vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TrainingCoursesEditForm from './TrainingCoursesEditForm.vue'

export default {
  components: {
    BCard,
    BSpinner,
    TrainingCoursesEditForm,
  },
  data() {
    return {
      trainingCourseData: null,
      sendInformationEmail: false,
      loading: false,
    }
  },
  beforeMount() {
    this.$store.dispatch('trainingCourse/fetchTrainingCourse', { id: this.$router.currentRoute.params.id })
      .then(response => {
        if (response && response.status === 200) {
          this.trainingCourseData = response.data
          this.trainingCourseData.trainer = this.trainingCourseData.trainer['@id']
        } else {
          this.trainingCourseData = undefined
        }
      })
  },
  methods: {

    submit() {
      let payload
      if (this.isAdmin) {
        payload = { ...this.trainingCourseData, sendInformationEmail: this.sendInformationEmail }
      } else {
        payload = {
          description: this.trainingCourseData.description,
          afterTrainingMaterials: this.trainingCourseData.afterTrainingMaterials,
          informationMaterials: this.trainingCourseData.informationMaterials,
          sendInformationEmail: this.sendInformationEmail,
        }
      }
      payload.afterTrainingMaterials = payload.afterTrainingMaterials.map(file => (file['@id'] ? file['@id'] : file))
      payload.informationMaterials = payload.informationMaterials.map(file => (file['@id'] ? file['@id'] : file))
      this.loading = true
      store
        .dispatch('trainingCourse/editTrainingCourse', { id: this.trainingCourseData.id, payload })
        .then(response => {
          if (response && response.status === 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Zmieniono szkolenie',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$router.push('/training-courses/list')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Nie udało się zmodyfikować szkolenia.',
                icon: 'AlertCircleIcon',
                variant: 'danger',
                text: response ? response.data['hydra:description'] : null,
              },
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style>

</style>
